const axios = window.axios
const url = '/api/v2/location/cities/'
const urlImages = '/api/v2/location/citiy-image/'
const urlPlaces = '/api/v2/location/places/'


export const LocationApiUrls = {
    // cities местность
    async getCities(params){
        return (await axios.get(`${url}`, { params: params })).data.results;
    },
    async createCity(body){
        return (await axios.post(`${url}`, body)).data;
    },
    async getCity(id){
        return (await axios.get(`${url}${id}/`)).data;
    },
    async setCity(id, body){
        return (await axios.put(`${url}${id}/`, body)).data;
    },
    async deleteCity(id){
        return (await axios.delete(`${url}${id}/`)).data;
    },


    // images
    async setImage(id, formdata){
        return (await axios.put(`${urlImages}${id}/`, formdata)).data;
    },


    // places подместность
    async getPlaces(params){
        return (await axios.get(`${urlPlaces}`, { params: params })).data.results;
    },
    async createPlace(body){
        return (await axios.post(`${urlPlaces}`, body)).data;
    },
    async getPlace(id){
        return (await axios.get(`${urlPlaces}${id}/`)).data;
    },
    async setPlace(id, body){
        return (await axios.put(`${urlPlaces}${id}/`, body)).data;
    },
    async deletePlace(id){
        return (await axios.delete(`${urlPlaces}${id}/`)).data;
    },

}